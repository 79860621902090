import template from './contact-search-dropdown.html';
import ContactModel from '../../lib/models/Contact.js';

/**
* @class ContactSearchDropdownComponentViewModel
* @component name - search-dropdown
* @constructor
* @param {object[]} selectedContact - selected item
* @param {text} nameField - name of the cache to use if no available_items is passed
* @param {text} [placeholder] - placeholder of the dropdown
*/
class ContactSearchDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.selectedContacts = ko_helper.safe_observableArray(params.selectedContacts); /* Selected contacts in case of multiselect */
		this.selectedContact = ko_helper.safe_observable(params.selectedContact); /* Selected contact */
		this.contactType = ko_helper.safe_observable(params.contactType || null); /* Contact type to search */
		this.placeholder = ko_helper.safe_observable(params.placeholder || 'Search contact...');
		this.filteredItems = ko.observableArray([]);
		this.multiple = ko_helper.safe_observable(params.multiple ?? false);
		this.nameField = ko.observable(params.nameField || 'name');
		this.allowBlank = ko.observable(params.allowBlank ?? true);

		this.searchText = ko.observable();

		this.init();
	}

	async init ()
	{
		/* Check if the contact list is preloaded */
		const Lists = window.Grape.Contacts.Lists;
		if (Lists.hasOwnProperty(this.contactType()))
		{
			const List = Lists[this.contactType()];
			this.filteredItems(List);
			/* If the list contains a single item, autoselect it */
			if (List.length == 1)
			{
				await List[0].fetch();
				this.selectedContact(List[0]);
				this.selectedContacts([List[0]]);
			}
		}
		else if (this.filteredItems().length == 1)
		{
			this.selectedContact(this.filteredItems()[0]);
		}

		this.searchText('');
		await this.search();
	}

	async search ()
	{
		let search_text = this.searchText();
		if (search_text == '') search_text = ':*';

		const results = await Grape.fetches.getJSON('/api/contacts/search', {searchtext: search_text, contact_type: this.contactType()});
		const list = [];
		for (let c of results.contacts)
		{
			const contact = new ContactModel();
			await contact.set(c);

			list.push(contact);
		}
		this.filteredItems(list);
		if (list.length == 1 && (!this.selectedContact() || this.selectedContact().refnr() == list[0]?.refnr()))
			this.selectedContact(list[0]);

		this.searchText('');
	}

	itemClick (row)
	{
		this.searchText('');

		if (row === this.selectedContact())
			this.selectedContact(null);
		else
			this.selectedContact(row);
	}

	clearSearch ()
	{
		this.searchText('');
		this.selectedContact(null);
		this.selectedContacts([]);
		this.search();
	}
}

export default {
	name: 'contact-search-dropdown',
	viewModel: ContactSearchDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}
