import template from './bulk_add.html';
import ContactModel from '../../../lib/models/Contact.js';

class BulkAddContactsViewModel
{
	constructor (page)
	{
		this.page = page;
		this.contacts = ko.observableArray([]);
		this.contact_types = ko.observableArray([]);
	}

	btn_close_page_click ()
	{
		history.back();
	}

	btn_remove_item_click (item)
	{
		this.contacts.remove(item);
	}

	parse_csv (csv)
	{
		let lines = csv.split('\n');
		let result = [];
		for (let i = 0; i < lines.length; i++)
		{
			let line = lines[i].split(',').map(cell => {
				// Remove quotes if present and trim whitespace
				return cell.replace(/^"|"$/g, '').trim();
			});
			result.push(line);
		}
		return result;
	}

	upload_csv (event)
	{
		let file = event.target.files[0];
		let reader = new FileReader();
		reader.onload = () => {
			let lines = this.parse_csv(reader.result);
			let errors = [];
			
			for (let i=1; i < lines.length; i++)
			{
				let data = lines[i];
				if (data.length > 1 && data[0] !== '')
				{
					if (!data[0] || !data[1] || !data[2])
					{
						errors.push(`Missing required fields in row ${i + 1}`);
						this.contacts([]);
					}

					if (errors.length > 0)
					{
						Grape.alerts.alert({
							title: 'Error',
							type: 'error',
							message: errors.join('\n'),
						});
						return;
					}

					// Split contact types by semicolon and trim whitespace
					let types = data[1].split(';').map(type => type.trim());
					
					let item = {
						name: data[0],
						contact_types: ko.observableArray([]),
						email: data[2],
						phone: data[3] || '',
						img_url: data[4] || ''
					};

					// Find matching contact type objects from the available types
					let matchingTypes = this.contact_types().filter(type => types.includes(type.name));
					if (matchingTypes.length === 0) {
						errors.push(`No matching contact types found for: ${types.join(', ')} in row ${i + 1}`);
					}
					item.contact_types(matchingTypes);
					
					this.contacts.push(item);
				}
			}
			if (errors.length > 0) {
				Grape.alerts.alert({
					title: 'Error',
					type: 'error',
					message: errors.join('\n'),
				});
				return;
			}
			Grape.alerts.alert({title: 'Success', type: 'success', message: 'Upload complete!'});
		}
		reader.readAsText(file);
	}
}

class BulkAddContacts
{
	constructor (bindings)
	{
		document.title = 'Bulk Add Contacts';
		this.viewModel = new BulkAddContactsViewModel(this);
		this.bindings = bindings;
	}

	async init ()
	{
		try {
			// Load contact types from cache
			const contactTypes = await Grape.cache.fetch('ContactTypesLookup') ?? [];
			this.viewModel.contact_types(contactTypes);
		} catch (error) {
			Grape.alerts.alert({title: 'Error', type: 'error', message: 'Failed to load contact types'});
			console.error(error);
		}
	}

	validate_inputs ()
	{
		for (let i = 0; i < this.viewModel.contacts().length; i++)
		{
			let item = this.viewModel.contacts()[i];
			if (!item.name || !item.contact_types().length || !item.email) 
			{
				Grape.alerts.alert({
					title: 'Error',
					type: 'error',
					message: `Please fill in all required fields for the contact in row ${i + 1} before saving.`
				});
				return false;
			}
		}
		return true;
	}

	async btn_save_contacts_click () 
	{
		if (this.validate_inputs())
		{
			let confirm = await Grape.alerts.confirm({ 
				type: 'warning', 
				title: 'Create New Contacts', 
				message: `Create ${this.viewModel.contacts().length} new contacts?` 
			});
			
			if (!confirm) {
				return;
			}

			for (let i = 0; i < this.viewModel.contacts().length; i++)
			{
				let item = this.viewModel.contacts()[i];

				let contact = new ContactModel();
				await contact.set({
					name: item.name,
					contact_types: item.contact_types().map(type => type.name),
					general: {
						name: item.name,
						email: item.email,
						phone: item.phone,
						img_url: item.img_url
					}
				});

				try
				{
					let res = await contact.save();
					if (res.status !== 'OK') {
						throw new Error(res.message || 'Failed to save contact');
					}
				}
				catch (error) {
					console.error(error);
					Grape.alerts.alert({
						title: 'Error',
						type: 'error',
						message: `Failed to save contact: ${item.name}`
					});
					return;
				}
			}
			
			Grape.alerts.alert({
				type: 'success', 
				message: 'Contacts Saved', 
				title: 'Success', 
			});
			this.viewModel.btn_close_page_click(); 
		}
	}
}

export default {
	route: '/ui/contacts/bulk_add',
	page_class: BulkAddContacts,
	template: template
} 