import template from './contact-history.html';

class ContactHistoryVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.dialog_title = ko.observable('Contact History');
		this.reload = ko.observable();
		this.table_params = ko.observable();
	}

	async load()
	{
		this.dialog.updateData();
	}

	async close_click()
	{
		this.dialog.close(false);
	}
}

class ContactHistoryDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new ContactHistoryVM(this);
		this.name = 'ContactHistory';
		
		this.init();
	}

	async init()
	{
		this.viewModel.load();
	}

	async updateData()
	{
		this.viewModel.table_params({
			schema: ko.observable('contacts'),
			table: ko.observable('contact_history'),
			table_class: 'ps-table ps-table-compact',
			fields: ko.observableArray([
				{ field: 'timestamp', name: 'Event Date' },
				{ field: 'action', name: 'Action' },
				{ field: 'user', name: 'By whom' }
			])
		});

		this.viewModel.reload(Date.now());
	}
}

export default {
	name: 'ContactHistory',
	dialog_class: ContactHistoryDialog,
	template: template,
	provider: 'ps'
}; 