// COMPONENTS
import contact_search_dropdown from './contact-search-dropdown/contact-search-dropdown.js';
import contact_card from './contact-card/contact-card.js';

// TABS COMPONENTS
import contact_info from './contacts-dashboard-tabs/contact-overview.js';
import contact_edit from './contacts-dashboard-tabs/contact-edit.js';

import contact_list from './contact-list/contact-list.js';
import contact_filter from './filter-contacts/filter-contacts.js';
import contact_create from './contact-create/contact-create.js';

export default [
	contact_search_dropdown,
	contact_edit,
	contact_info,
	contact_create,
	contact_filter,
	contact_list,
	contact_card
];
