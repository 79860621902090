
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';

import package_json from '../package.json';

/* List used as tabs by /ui/contacts/dashboard page */
window.Grape.registry.addRegister('tabs_dashboard.contacts_page');
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Overview', { component: 'ko-contact-tab-overview', idx: 1});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Change Details', { component: 'ko-contact-tab-edit', idx: 2});

window.Grape.registry.addRegister('info_tab.panels');
/* these components are to be used inside the specific tab as cards/panels */
window.Grape.registry.addItem('contacts_overview_tab.panels', 'Contact Details', { component: 'contact-card'});

(function() {
	window.Grape.modules.push(package_json);

	Grape.cache.registerCaches(appcaches);
	Grape.component.registerComponents(components);
	Grape.dialog.registerDialogs(dialogs);
	Grape.pages.registerPages(pages);
	Grape.plugins.registerPlugins(plugins);
})();
