import template from './contact-list.html';

class ContactListViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact_types = params.types;
		this.reload = ko.observable();
		this.table_params = ko.observable();

		this.contact_types.subscribe((nv) => {
			this.updateData();
		});
	}

	async updateData ()
	{
		try {
			// Use the first contact type
			let types = this.contact_types();

			if (!types || types.length === 0)
			{
				console.warn('No contact types provided');
				return;
			}

			let type = types[0].toLowerCase();

			this.table_params(
				{
					schema: ko.observable('contacts'),
					table: ko.observable(`dv_${type}`),
					table_class: 'ps-table ps-table-compact',
					search_fields: ko.observableArray([{ field: 'name' }, { field: 'email' }]),
					page_size: ko.observable(5),
					page_size_options: ko.observableArray([10, 20, 50, 100]),
					permanent_filter: ko.observableArray([]),
					actions: ko.observableArray([
						{
							class: 'ps-btn-sm ps-btn-success ps-table-action-green',
							icon: 'fa fa-solid fa-eye',
							action: (data) => this.view_contact(data),
							title: 'View this contact'
						}, {
							class: 'ps-btn-sm ps-btn-success ps-table-action-grey',
							icon: 'fa fa-solid fa-clock',
							action: (data) => this.view_history(data),
							title: 'View History/Log file'
						}
					]),
					filter_join: ko.observable('AND'),
					fields: ko.observableArray([
						{
							field: 'img_url', name: ' ', formatter: 'img', visible: false
						},
						{
							field: 'name', name: 'Name'
						},
						{
							field: 'email', name: 'Email'
						},
						{
							field: 'firstname', name: 'First Name'
						},
						{
							field: 'surname', name: 'Surname'
						},
						{
							field: 'billing_address', name: 'Billing Address'
						},
						{
							field: 'shipping_address', name: 'Shipping Address'
						},
						{
							name: 'Actions', actions: true
						}
					])
				}
			);

			this.reload(Date.now());
	
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	view_contact (contact)
	{
		Grape.navigate(`/ui/contacts/dashboard?refnr=${contact.refnr}`);
	}

	view_history (contact)
	{
		let data = ko.toJS(contact);
		Grape.dialog.open('ContactHistory', data);
	}
}

export default {
	name: 'ko-contact-list',
	viewModel: ContactListViewModel,
	module_type: 'ko',
	template: template
}
