import template from './contact-create.html';

class ContactCreateViewModel
{
	constructor (page)
	{
		this.page = page;
		this.contact = ko.observable(this.page.bindings.contact);
		this.bindings = page.bindings;
	}

	btn_back_click()
	{
		history.back();
	}
}

class ContactCreatePageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ContactCreateViewModel(this);
		this.name = 'ContactCreatePageClass';
		document.title = 'Create Contact';
	}

	async updateData(bindings, params, options)
	{
		if (params.contact_types) 
			this.bindings.contact_types = params.contact_types;	
	}
}

export default {
	route: '[/ui/contacts/]create',
	page_class: ContactCreatePageClass,
	template: template
}