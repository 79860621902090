
/**
 * Contact Model
 */
class ContactModel
{

	constructor (input)
	{
		this.contact_uuid = ko.observable();
		this.contact_types = ko.observableArray([]);
		this.parent_contact_uuid = ko.observable(null);
		this.refnr = ko.observable('');
		this.name = ko.observable('');
		this.last_updated = ko.observable(null);
		this.date_deleted = ko.observable(null);
		this.fv = {}; /* Flattened field values */
		this.fields = ko.observable();

		this.field_tables = ko.observableArray([]); /* Field table names, based on contact type */
		this.field_definitions = ko.observableArray([]);
		this.last_fetch = ko.observable(null);
	}

	async set(input)
	{
		console.log('SET IN MODEL', input);
		const field_definitions = await window.Grape.cache.get('ContactFieldTables');
		const types = await window.Grape.cache.get('ContactTypesLookup');

		if (input.hasOwnProperty('contact_uuid'))
			this.contact_uuid(input.contact_uuid);

		if (input.hasOwnProperty('contact_types'))
		{
			this.contact_types(input.contact_types);
		}

		let fts = [];
		for (let type of types)
		{
			if (this.contact_types()?.indexOf(type.name) > -1)
				fts = fts.concat(type.data.field_tables);
		}
		this.field_tables([...new Set(fts)]);

		if (input.hasOwnProperty('parent_contact_uuid'))
			this.parent_contact_uuid(input.parent_contact_uuid);

		if (input.hasOwnProperty('refnr'))
			this.refnr(input.refnr);

		if (input.hasOwnProperty('name'))
			this.name(input.name);

		if (input.hasOwnProperty('last_updated'))
			this.last_updated(input.last_updated);

		if (input.hasOwnProperty('date_deleted'))
			this.date_deleted(input.date_deleted);

		if (input.hasOwnProperty('fv')) // ReadOnly Field values
		{
			this.fv = input.fv;
			if (!this.name()) this.name(input.fv.name);
			if (!this.refnr()) this.refnr(input.fv.refnr);
		}

		// Save flattened values
		for (let ft of this.field_tables())
		{
			if (input.hasOwnProperty(ft))
			{
				for (let [k,v] of Object.entries(input[ft]))
				{
					// Breaks fields after edit -> click on overview to see new values
					/* if (!this.fv.hasOwnProperty(k))
						this.fv[k] = v;
					else
						this.fv[`${k}_${ft}`] = v; */

					this.fv[k] = v;
				}
			}
		}

		// Set up fields on input
		let fields = {};
		for (let ft of this.field_tables())
		{
			let table_def = field_definitions.find(fd => fd.category == ft);
			let input_table = input[ft] ?? {};

			for (let field_def of table_def.fields)
			{
				let input_field = input_table[field_def.field_name] ?? '';
				input_table[field_def.field_name] = ko.observable(input_field);
			}

			fields[ft] = input_table;
		}
		this.fields(fields);

		// Hack to update name and refnr
		if (fields?.general)
		{
			let name = fields?.general?.name;
			if (!this.name()) this.name(name());
			name?.subscribe(n => {
				this.name(n);
			});
		}
	}

	get()
	{
		console.log('GET IN MODEL');
		let obj = {
			contact_uuid: this.contact_uuid(),
			contact_types: this.contact_types(),
			parent_contact_uuid: this.parent_contact_uuid(),
			refnr: this.refnr(),
			name: this.name(),
			fts: this.field_tables(),
			last_updated: this.last_updated(),
			date_deleted: this.date_deleted()
		};

		for (let ft of obj.fts)
			obj[ft] = ko.mapping.toJS(this.fields()[ft]);

		return obj;
	}

	async clear()
	{
		console.log('CLEAR IN MODEL');
		this.set({
			contact_uuid: null
		});
	}

	async fetch(ref, uuid)
	{
		console.log('FETCH IN MODEL', ref, uuid);
		let query = {};
		let refnr = ref ?? this.refnr();
		let contact_uuid = uuid ?? this.contact_uuid();

		if (refnr)
			query = {refnr};
		if (contact_uuid)
			query = {contact_uuid};

		let contact = await Grape.fetches.getJSON(`/api/contacts`, query);
		this.set(contact);
		this.last_fetch((new Date()));
	}

	async save()
	{
		console.log('SAVE IN MODEL');
		const payload = this.get();
		const res = await Grape.fetches.postJSON(`/api/contacts/upsert/`, payload);
		return res;
	}

	async load(refnr)
	{
		console.log('LOAD IN MODEL', refnr);
		// TODO: Should the newly created contact card be opened or just stay on create-contact?
		let contact = await Grape.fetches.getJSON(`api/contacts/?refnr=${encodeURI(refnr)}`);
	}
}

export default ContactModel;
