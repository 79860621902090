import template from './contact-types.html';

class ContactsTypesVM
{
	constructor (page)
	{
		this.page = page;
		this.contacts_types = ko.observableArray();
	}

	async load ()
	{
		let options = {
			table: 'v_lookup_value',
			schema: 'grape',
			sortfield: 'lookup_value_id',
			filter: [{ field: 'lookup_name', operand: '=', value: 'ContactTypes' }]
		}

		let response = await Grape.fetches.getJSON('/api/record', options);
		this.contacts_types(response.records);
	}

	async editType (data)
	{
		let response = await Grape.dialog.open('ContactsTypeEdit',{type: data});
		this.load();
	}
	
	async deleteType (data)
	{
		try
		{
			let result = await Grape.fetches.deleteJSON('api/contacts/type', {lookup_value_id: data.lookup_value_id});
			if (result.status != 'ERROR')
			{
				this.load();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}
	}
}

class ContactsTypesPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ContactsTypesVM(this);
	}

	async init ()
	{
		document.title = 'Contacts Types';
		await this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/contacts/]types',
	page_class: ContactsTypesPage,
	template: template
}
