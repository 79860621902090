
import template from './contact-card.html';
import ContactModel from '../../lib/models/Contact'

/**
 * @kind component
 * @class ContactViewModel
 * @description This is contact ko component for the display of contact data
 */
class ContactCardViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko.observable();
		this.field_tables = ko.observableArray();

		this.load();
	}

	async load()
	{
		const field_tables = await Grape.cache.get('ContactFieldTables');
		this.field_tables(field_tables);
		if (this.params.contact)
		{
			let contact = null;
			let unwrapped;
			if (ko.isObservable(this.params.contact))
			{
				unwrapped = this.params.contact();
				this.params.contact.subscribe(async (nv) => {
					if (nv instanceof ContactModel)
						this.contact(nv);
					else if (nv instanceof Object)
					{
						let contact = new ContactModel();
						await contact.set(nv);

						if (contact.contact_uuid() || contact.refnr())
							await contact.fetch();

						this.contact(contact);
					}
				});
			}
			else
				unwrapped = this.params.contact;

			if (unwrapped instanceof ContactModel)
				contact = unwrapped;
			else if (unwrapped instanceof Object)
			{
				contact = new ContactModel();
				await contact.set(unwrapped);

				if (contact.contact_uuid() || contact.refnr())
					await contact.fetch();
			}

			this.contact(contact);
		}
	}
}

export default {
	name: 'contact-card',
	viewModel: ContactCardViewModel,
	module_type: 'ko',
	template: template
};
