import template from './contact-dashboard.html';
import ContactModel from '../../lib/models/Contact.js';

class ContactsDashboardViewModel
{
	constructor (page)
	{
		this.page = page;
		this.contact = ko.observable(null);
	}

	btn_back_click()
	{
		history.back();
	}
}

class ContactsDashboardPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ContactsDashboardViewModel(this);
		this.name = 'ContactsDashboardPageClass';
		document.title = 'Contact Dashboard';

	}

	async init()
	{
		let contact;
		if (this.bindings.refnr)
		{
			contact = new ContactModel();
			await contact.fetch(this.bindings.refnr);
		}
		else if (this.bindings.uuid)
		{
			contact = new ContactModel();
			await contact.fetch(null, this.bindings.uuid);
		}
		else if (this.bindings.contact && this.bindings.contact instanceof ContactModel)
			contact = this.bindings.contact;

		if (contact)
			this.viewModel.contact(contact);
	}
}

export default {
	route: '[/ui/contacts/]dashboard',
	page_class: ContactsDashboardPageClass,
	template: template
}
