/**
 * This is an example of a GrapeUIPlugin that adds functionality to the front-end
 * @kind plugin
 * @class ContactsPlugin
 */

import ContactModel from '../lib/models/Contact.js';

class ContactsPlugin {
	constructor(Grape, options) {
		this.Grape = Grape;
		this.options = options;
		window.Grape.Contacts = this;

		// Preloaded contact types
		this.Lists = {};
	}

	async afterInit() {
		this.loadContactTypes();
	}

	async onSessionChange()
	{
		this.loadContactTypes();
	}

	async loadContactTypes()
	{
		if (window.Grape.currentSession && window.Grape.currentSession.session_uuid)
		{
			const contactTypes = await Grape.cache.fetch('ContactTypesLookup');
			for (let ct of contactTypes)
			{
				if ((ct.data?.preload || false) === true)
				{
					await this._loadList(ct.name);
				}
			}
		}
	}

	async _loadList(contactType)
	{
		console.debug('Preloading contact list:', contactType);
		const tname = `dv_${contactType.toLowerCase()}`;
		const list = [];
		let remaining = 0, fetched = 0;
		do {
			const result = await Grape.fetches.getJSON(`/api/record`, {schema: 'contacts', table: tname, limit: 100});
			for (let r of result.records)
			{
				const c = new ContactModel();
				await c.set({
					contact_uuid: r.contact_uuid,
					refnr: r.refnr,
					fv: r
				});
				list.push(c);
			}
			fetched += result.records.length;
			remaining = result.total - fetched;
		} while (remaining > 0);
		this.Lists[contactType] = list;
	}

}

export default ContactsPlugin;
