
import ContactsPage from './contacts/contacts.js';
import BulkAddContacts from './contacts/bulk_add/bulk_add.js';
import ContactsTypes from './contact-types/contact-types.js';
import ContactCreate from './contact-create/contact-create.js';
import ContactsDashboard from './contact-dashboard/contact-dashboard.js';

export default [
	ContactsPage,
	BulkAddContacts,
	ContactsTypes,
	ContactsDashboard,
	ContactCreate
];
