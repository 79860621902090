import template from './filter-contacts.html';

class ContactFilterViewModel
{
	constructor (params)
	{
		this.params = params;
		this.contact_types = ko.observableArray();
		this.selected_type = ko.observable();
		this.selected_types = ko.observable();
		this.search_text = ko.observable();
		this.loading = ko.observable(false);
		this.results = ko.observableArray([]);
		this.search_timeout;
		this.fields = ko.observableArray([]);
		this.visible_fields = ko.observableArray([]);
		this.fieldsInfo = [];

		this.search_text.subscribe((newValue) => {
			if (newValue === '')
				this.results([]);

			this.on_search_typing(newValue)
		});

		this.fields.subscribe((newValue)=>{
			localStorage.setItem('contactSearchFields', JSON.stringify(ko.mapping.toJS(newValue)));
		});

		this.field_value_formatter = this.field_value_formatter.bind(this);
		this.view_contact = this.view_contact.bind(this);

		this.init();
	}

	reset_fields()
	{
		localStorage.removeItem('contactSearchFields');
		this.populate_fields();
	}

	async on_search_typing(newValue)
	{
		clearTimeout(this.search_timeout);
		this.search_timeout = setTimeout(()=>{
			this.btn_search_click();
		}, 250);
	}

	async btn_search_click()
	{
		let search_text = this.search_text();
		if (!search_text || !search_text.trim())
			return;
		// TODO search_text cleanup/formatting
		this.loading(true);
		await Grape.fetches.getJSON(`api/contacts/search/?searchtext=${encodeURIComponent(search_text.trim().replace(/[+.()@&:*]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join(':* & ')+':*')}`).then((res) => {
			this.results(res.contacts || []);
			this.loading(false);
		});
	}

	view_contact(contact)
	{
		Grape.navigate('[/ui/contacts/]dashboard', {contact});
	};

	async field_value_formatter({name, data})
	{
		let value = '';
		let keys = name.split('.');
		if (typeof data[keys[0]] != 'undefined') 
		{
			if (keys.length === 1)
				value = data[keys[0]];
			else
				value = data[keys[0]][keys[1]];
		}

		value ??= '';
		if (typeof value !== 'string')
			value = JSON.stringify(value);
		let rg = new RegExp(`(${this.search_text().replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&").trim().split(' ').join('|')})`, 'ig');
		return value.replace(rg, '<span style="background-color:yellow">$1</span>');
	}

	async init ()
	{
		document.title = 'Contacts - Filter';
		await this.get_contact_types();
		this.get_fields_info();
		this.populate_fields();
	}

	async get_fields_info()
	{
		this.fieldsInfo = Grape.cache.get('ContactFieldTables');
	}

	async get_contact_types() 
	{
		let types = await Grape.cache.get('ContactTypesLookup');
		this.contact_types(types);
	}

	async populate_fields()
	{
		let fieldsInfo = await this.fieldsInfo;

		let selectedContact = this.selected_type(); // Assuming you have a selected_contact observable
		let fieldTables = selectedContact.field_tables; // Array of relevant field categories

		// Filter the fieldsInfo to only include relevant categories
		let filteredFieldsInfo = fieldsInfo.filter(category => fieldTables.includes(category.category));

		let fields = [{name: 'Ref#', path: 'refnr', visible: ko.observable(true)}];
		for (let category of filteredFieldsInfo) {
			for (let field of category.fields) {
				fields.push({name: field.field_desc, path: `${category.category}.${field.field_name}`, visible: ko.observable(true)});
			}
		}

		// if localstorage modify order and visibility
		/*try {
			let storedFields = JSON.parse(localStorage.getItem('contactSearchFields'));
			if (storedFields)
			{
				let order = [];
				let visMap = {};
				for (let field of storedFields)
				{
					visMap[field.name] = field.visible;
					order.push(field.name);
				}
				fields.sort( (a, b) => {
					let A = a['name'], B = b['name'];
					a.visible(visMap[A]);

					if (order.indexOf(A) > order.indexOf(B))
						return 1;
					else
						return -1;
				});
			}
		} catch(err) {
			//do nothing
		}*/
		this.fields(fields);
	}
}

export default {
	name: 'ko-filter-contacts',
	viewModel: ContactFilterViewModel,
	module_type: 'ko',
	template: template
}
