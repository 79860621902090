
import template from './contact-create.html';
import ContactModel from '../../lib/models/Contact.js'

/**
 * @kind component
 * @class ContactCreateViewModel
 * @description This is contact ko component for the create contacts
 */
class ContactCreateViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko.observable();

		this.support_save = ko_helper.safe_observable(params.support_save ?? true);
		if (ko.isObservable(params.contact_model))
			params.contact_model(this.contact);

		this.can_select_contact_types = ko.observable(params.can_select_contact_types ?? true);
		this.selected_contact_types = ko.observableArray([]); /* List of selected ContactTypeLookupItem */
		this.contact_types = ko.observableArray([]); /* List of available ContactTypeLookupItem */
		this.field_tables = ko.observableArray();

		this.display_field_tables = ko.pureComputed(() => {
			const table_names = [];
			for (let s of this.selected_contact_types())
				for (let ft of s.data.field_tables)
					if (table_names.indexOf(ft) < 0)
						table_names.push(ft);
			return table_names;
		});

		this.selected_contact_types.subscribe((val) => {
			if (this.contact())
			{
				let names = val.map(item => item.name);
				this.contact().set({ contact_types: names });
			}
		});

		this.load();
	}

	async load()
	{
		const ct = await Grape.cache.fetch('ContactTypesLookup') ?? [];
		this.contact_types(ct);
		const field_tables = await Grape.cache.get('ContactFieldTables') ?? [];
		this.field_tables(field_tables);

		const plist = [];
		if (Array.isArray(this.params.contact_types))
			for (let f of this.contact_types())
			{
				if (this.params.contact_types.indexOf(f.name) > -1)
					plist.push(f);
			}
		this.selected_contact_types(plist);

		const input = this.params.contact || {};
		input.contact_types = input.contact_types ?? plist.map(t=>t.name);
		const c = new ContactModel();
		await c.set(input);
		this.contact(c);
	}

	async saveContact()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Contact', message: 'Create new contact?' });
		if (!confirm)
			return

		let res = await this.contact().save();
		if (res.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Contact Saved', message:'Successfully saved contact.' });
			Grape.navigate(`/ui/contacts/dashboard?refnr=${res.refnr}`);
		}
		else
			Grape.alerts.alert( {type:'danger', title:'Contact Save Error', message:res.message });
	}

	async clear()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Clear', message: 'Clear and lose any changes?' });
		if (!confirm)
			return
		this.contact().clear();
	}
}

export default {
	name: 'contact-create',
	viewModel: ContactCreateViewModel,
	module_type: 'ko',
	template: template
};
