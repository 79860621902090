
export default {
	name: 'Contacts.Locations',
	options: {
		table: 'dv_location',
		schema: 'contacts'
	}
};


