// CACHES
import ContactFieldsInfo from './ContactFieldTables.js';
import ContactTypeLookup from './contact_type_lookup.js';
import Locations from './locations.js';

export default [
	ContactFieldsInfo,
	ContactTypeLookup,
	Locations
];
