
import template from './contact-edit.html';
import ContactModel from '../../lib/models/Contact.js'

/**
 * @kind component
 * @class ContactViewModel
 * @description This is contact ko component for the display and modification of contact data
 */
class ContactEditViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.item || new ContactModel());
		this.field_tables = ko.observable(null);
		this.load();
	}

	async load()
	{
		await new Promise((resolve, reject) => setTimeout(resolve, 100));

		let field_tables = await Grape.cache.get('ContactFieldTables') ?? [];
		let fts = {};
		let modelFields = this.contact().fields();

		for (let ft of field_tables)
		{
			let rfields = []; // render fields
			let categoryFields = (modelFields && modelFields.hasOwnProperty(ft.category)) ? modelFields[ft.category] : null;

			for (let field of ft.fields)
			{
				let input;

				// If the category and field exist, use the model's observable
				if (categoryFields && categoryFields.hasOwnProperty(field.field_name))
					input = categoryFields[field.field_name];
				else
					input = ko.observable('');

				let rfield = {
					type: field.field_data?.type || 'text',
					label: field.label,
					name: field.field_name,
					$input: input
					// TODO ADD FIELD TYPE OPTIONS HERE
				};
				rfields.push(rfield);
			}
			fts[ft.category] = { display_name: ft.display_name, fields: rfields };
		}
		this.field_tables(fts);
	}

	async saveContact()
	{
		if (!this.contact().refnr)
		{
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Contact', message: 'Create new contact?' });
			if (!confirm)
				return
		} else {
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Save Contact', message: 'Save Changes?' });
			if (!confirm)
				return
		}

		let res = await this.contact().save();
		if (res.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Contact Saved', message:'Successfully saved contact.' });
			await this.contact().fetch();
			Grape.navigate(`/ui/contacts/dashboard?refnr=${res.refnr}`);
		}
		else
			Grape.alerts.alert( {type:'danger', title:'Contact Save Error', message:res.message });
	}

	async clear()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Clear', message: 'Clear and lose any changes?' });
		if (!confirm)
			return
		this.contact().clear();
	}
}

export default {
	name: 'ko-contact-tab-edit',
	viewModel: ContactEditViewModel,
	module_type: 'ko',
	template: template
};
