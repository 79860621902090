/**
 * Main Contacts page with 3 parts: Contact search, Contact filter and Create Contact
 * Route: /ui/contacts
 */
import template from './contacts.html';

class ContactSearchVM
{
	constructor (page)
	{
		this.page = page;
		this.tab = ko.observable('contact_list');
		this.types = ko.observableArray([]);
		this.title = ko.observable();
	}

	btn_nav_contact_create_click()
	{
		let types = this.types();
		if (types && types.length > 0)
			Grape.navigate('[/ui/contacts/]create', { contact_types: types });
		else
			Grape.navigate('[/ui/contacts/]create');
	}

	btn_bulk_add_click()
	{
		Grape.navigate('/ui/contacts/bulk_add');
	}
}

class ContactSearchPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ContactSearchVM(this);
	}

	init ()
	{
		document.title = 'Contacts';
	}

	async updateData(bindings, params, options)
	{
		let types = [];
		if (params.hasOwnProperty('type'))
			types.push(params.type);
		else if (params.hasOwnProperty('types'))
			types = params.types;

		this.viewModel.types(types);
		this.viewModel.title(params.title || 'Contacts');
	}
}

export default {
	route: '/ui/contacts',
	page_class: ContactSearchPage,
	template: template
}
